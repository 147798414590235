import React from 'react';

class Hello extends React.Component {
    render() {
        return (
            <div id="header">
                <h1>Hello, World</h1>
            </div>
        );
    }
}

export default Hello;